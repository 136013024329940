document.addEventListener("DOMContentLoaded", function () {
    // Кнопка меню
    // let menus = document.querySelectorAll(".hamburger-menu");
    // for (let i = 0; i < menus.length; i++) {
    //     menus[i].addEventListener("click", function () {
    //         menus[i].classList.toggle("open");
    //     });
    // }

    // Слайдер баннеров на главной
    const swiperBanner = new Swiper(".banner", {
        slidesPerView: 1, // Слайдов на экран
        spaceBetween: 0, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 300, // Скорость анимации
        parallax: true, // Параллакс эффект
        // autoplay: { // Автоплей
        // 	delay: 8000, // Время автоплея
        // 	disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
        // 	pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        // },
        navigation: {
            nextEl: ".banner-arrows__next",
            prevEl: ".banner-arrows__prev",
        },
    });

    // Табы товара
    const tabs = document.querySelectorAll(".product-tab");
    const contents = document.querySelectorAll(".product-tabs__content_item");
    for (let i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", () => {
            for (let j = 0; j < contents.length; j++) {
                contents[j].classList.remove("active");
            }
            for (let jj = 0; jj < tabs.length; jj++) {
                tabs[jj].classList.remove("active");
            }
            contents[i].classList.add("active");
            tabs[i].classList.add("active");
        });
    }

    // Аккордеон фильтров
    // const filterTitle = document.querySelectorAll(".catalog-filter__title");
    // const filterContent = document.querySelectorAll(".catalog-filter__list");
    // for (let i = 0; i < filterTitle.length; i++) {
    //     filterTitle[i].addEventListener("click", () => {
    //         if (filterTitle[i].classList.contains("active")) {
    //             filterContent[i].classList.remove("active");
    //             filterTitle[i].classList.remove("active");
    //         } else {
    //             for (let j = 0; j < filterContent.length; j++) {
    //                 filterContent[j].classList.remove("active");
    //             }
    //             for (let jj = 0; jj < filterTitle.length; jj++) {
    //                 filterTitle[jj].classList.remove("active");
    //             }
    //             filterContent[i].classList.add("active");
    //             filterTitle[i].classList.add("active");
    //         }
    //     });
    // }

    // Слайдер документов
    const swiperDocs = new Swiper(".documents", {
        slidesPerView: 3, // Слайдов на экран
        spaceBetween: 45, // Отступы между слайдами
        centeredSlides: true, // Центровка слайдов
        loop: true, // Бесконечная прокрутка
        lazy: true, // Ленивая загрузка
        speed: 300, // Скорость анимации
        parallax: true, // Параллакс эффект
        autoplay: {
            // Автоплей
            delay: 5000, // Время автоплея
            disableOnInteraction: false, // Отключение взаимодействия со слайдером при автоплее
            pauseOnMouseEnter: true, // Остановка автоплея при фокусе
        },
        navigation: {
            nextEl: ".documents-arrows__next",
            prevEl: ".documents-arrows__prev",
        },
        pagination: {
            el: ".documents-pagination",
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            769: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1025: {
                slidesPerView: 3,
                spaceBetween: 45,
            },
        },
    });

    // Табы информации об отправителе
    const ClientTabs = document.querySelectorAll(".repair-client__tab");
    const ClientTContents = document.querySelectorAll(
        ".repair-client__content_item"
    );
    // Кнопки для отправки форм
    const submitButtonFiz = document.querySelector(".repair-submit.form-fiz");
    const submitButtonYur = document.querySelector(".repair-submit.form-yur");
    for (let i = 0; i < ClientTabs.length; i++) {
        ClientTabs[i].addEventListener("click", () => {
            for (let j = 0; j < ClientTContents.length; j++) {
                ClientTContents[j].classList.remove("active");
            }
            for (let jj = 0; jj < ClientTabs.length; jj++) {
                ClientTabs[jj].classList.remove("active");
            }
            ClientTContents[i].classList.add("active");
            ClientTabs[i].classList.add("active");
            // Переключение кнопок для отправки форм
            if (i === 0) {
                submitButtonYur.classList.remove("active");
                submitButtonFiz.classList.add("active");
            }
            if (i === 1) {
                submitButtonFiz.classList.remove("active");
                submitButtonYur.classList.add("active");
            }
        });
    }

    // Страница "Сдать оборудование в ремонт"
    let equipment = $(".repair-equipment__list");
    let equipmentItemAdd = $(".repair-equipment__add button");

    // Обновление таблицы
    function equipmentUpdate() {
        equipmentLength = $(
            ".repair-equipment__list .repair-equipment__item"
        ).length;
        if (equipmentLength == 1) {
            $(".repair-equipment__delete").hide();
        } else {
            $(".repair-equipment__delete").show();
        }
        if (equipmentLength == 10) {
            $(".repair-equipment__add").hide();
        } else {
            $(".repair-equipment__add").show();
        }
    }
    equipmentUpdate();

    // Добавление  строки в таблице оборудования
    function duplicateItem() {
        equipment.append(
            '<div class="repair-equipment__item">\
                <div class="repair-equipment__item_cell">\
                    <span></span>\
                </div>\
                <div class="repair-equipment__item_cell">\
                    <input type="text" name="equipment" placeholder="Оборудование">\
                </div>\
                <div class="repair-equipment__item_cell">\
                    <input type="text" name="serial" placeholder="Серийный номер">\
                </div>\
                <div class="repair-equipment__item_cell">\
                    <input type="text" name="defect" placeholder="Неисправность">\
                </div>\
                <div class="repair-equipment__item_cell">\
                    <button type="button" class="repair-equipment__delete">\
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
                            <path d="M1 1L17 17" stroke="#FC3931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>\
                            <path d="M1 17L17 1" stroke="#FC3931" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>\
                        </svg>\
                    </button>\
                </div>\
            </div>'
        );
    }
    equipmentItemAdd.on("click", function () {
        duplicateItem();
        equipmentUpdate();
    });

    // Удаление строки в таблице оборудования
    $(document).on("click", ".repair-equipment__delete", function () {
        $(this).closest(".repair-equipment__item").remove();
        equipmentUpdate();
    });
});
